import React from "react";

const Footer_new = () => {
  return (
    <>
      <footer class="bg-[#25272D] overflow-hidden  bg-[url('https://img.invupos.com/clauweb/Footer/clau-logo-overlay.svg')] lg:bg-[length:900px] md:bg-[length:700px] bg-[length:400px] lg:bg-[right_top_24rem] md:bg-[right_top_38rem] bg-[right_top_53rem] bg-no-repeat">
        <div class="mx-auto max-w-screen-xl px-4 pt-12 pb-8 sm:px-6 lg:px-8">
          <div class="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-32">
            <div class="mx-auto lg:max-w-none">
              <div class="flex justify-start text-teal-600 sm:justify-start dark:text-teal-300">
                <img src="https://img.invupos.com/clauweb/Footer/CLAU-LOGO-BLACK.svg" className="w-64" />
              </div>
              <p class="mt-8 text-gray-400 lg:text-left md:text-left lg:text-lg text-left">
                Es todo lo que tu negocio necesita centralizado en un solo
                lugar, Paginas web, app moviles, integraciones delivery,
                integraciónes con sistemas POS, Fidelizacion de cleintes y mucho
                más.
              </p>

              <div class="mt-6 mb-6">
                <h2 class="mt-10 text-2xl font-bold mb-6 text-white">
                  Subscribe to our newsletter
                </h2>
                <form class="mt-6">
                  <div class="relative max-w-lg">
                    <label class="sr-only" for="email">
                      {" "}
                      Email{" "}
                    </label>

                    <input
                      class="w-full border-b border-gray-400 bg-[#25272D] p-4 pr-32 text-lg font-semibold text-white placeholder-white focus:outline-none"
                      id="email"
                      type="email"
                      placeholder="Email address"
                    />

                    <button
                      type="submit"
                      class="absolute right-0 top-0 h-full rounded-t-lg bg-[#EF7D6D] px-6 flex items-center justify-center hover:brightness-110 transition"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right text-black"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 6l6 6l-6 6" />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-8 lg:grid-cols-3 md:grid-cols-3 lg:text-left">
              <div class="text-left lg:ml-auto">
                <strong class="font-semibold text-lg text-gray-200">
                  {" "}
                  Resource{" "}
                </strong>

                <ul class="mt-6 space-y-2 cursor-pointer">
                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      {" "}
                      Marketing{" "}
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      Blog
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      Customer Stories
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      Information
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      Legal
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      Payments
                    </a>
                  </li>
                </ul>
              </div>

              <div class="text-left lg:ml-auto">
                <strong class="font-semibold text-lg text-gray-200">
                  {" "}
                  Career{" "}
                </strong>

                <ul class="mt-6 space-y-2 cursor-pointer">
                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      {" "}
                      Jobs{" "}
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      {" "}
                      Tips & Tricks{" "}
                    </a>
                  </li>
                </ul>
              </div>

              <div class="text-left lg:ml-auto">
                <strong class="font-semibold text-lg text-gray-200">
                  {" "}
                  Help{" "}
                </strong>

                <ul class="mt-6 space-y-3 cursor-pointer">
                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      {" "}
                      FAQs{" "}
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      {" "}
                      Help Center{" "}
                    </a>
                  </li>

                  <li>
                    <a class="text-gray-800 transition hover:text-gray-700/75 dark:text-gray-200 dark:hover:text-white/75">
                      {" "}
                      Support{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mt-16 border-t border-[#7c7d81] pt-8 sm:flex sm:items-center sm:justify-between lg:mt-32">
            <ul class="flex flex-wrap justify-center gap-4 text-xs lg:justify-end">
              <li>
                <a
                  href="#"
                  class="text-gray-500 transition hover:opacity-75 dark:text-gray-400"
                >
                  Terms
                </a>
              </li>

              <li>
                <a
                  href="#"
                  class="text-gray-500 transition hover:opacity-75 dark:text-gray-400"
                >
                  Privacy
                </a>
              </li>

              <li>
                <a
                  href="#"
                  class="text-gray-500 transition hover:opacity-75 dark:text-gray-400"
                >
                  Cookies
                </a>
              </li>

              <li>
                <a
                  href="#"
                  class="text-gray-500 transition hover:opacity-75 dark:text-gray-400"
                >
                  Legal
                </a>
              </li>

              <li>
                <a
                  href="#"
                  class="text-gray-500 transition hover:opacity-75 dark:text-gray-400"
                >
                  Recalls
                </a>
              </li>
            </ul>

            <ul class="mt-8 flex justify-center gap-3 sm:mt-0 lg:justify-end">
              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  target="_blank"
                  class="group flex size-14 p-1.5 items-center justify-center rounded-full border border-gray-500 text-white transition hover:opacity-75"
                >
                  <span class="sr-only">YouTube</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="icon icon-tabler icons-tabler-filled icon-tabler-brand-youtube"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 3a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-12a5 5 0 0 1 -5 -5v-8a5 5 0 0 1 5 -5zm-9 6v6a1 1 0 0 0 1.514 .857l5 -3a1 1 0 0 0 0 -1.714l-5 -3a1 1 0 0 0 -1.514 .857z" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  target="_blank"
                  class="group flex size-14 p-1.5 items-center justify-center rounded-full border border-gray-500 text-white transition hover:opacity-75"
                >
                  <span class="sr-only">LinkedIn</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="icon icon-tabler icons-tabler-filled icon-tabler-brand-linkedin"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M17 2a5 5 0 0 1 5 5v10a5 5 0 0 1 -5 5h-10a5 5 0 0 1 -5 -5v-10a5 5 0 0 1 5 -5zm-9 8a1 1 0 0 0 -1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0 -1 -1m6 0a3 3 0 0 0 -1.168 .236l-.125 .057a1 1 0 0 0 -1.707 .707v5a1 1 0 0 0 2 0v-3a1 1 0 0 1 2 0v3a1 1 0 0 0 2 0v-3a3 3 0 0 0 -3 -3m-6 -3a1 1 0 0 0 -.993 .883l-.007 .127a1 1 0 0 0 1.993 .117l.007 -.127a1 1 0 0 0 -1 -1" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  target="_blank"
                  class="group flex size-14 p-1.5 items-center justify-center rounded-full border border-gray-500 text-white transition hover:opacity-75"
                >
                  <span class="sr-only">Instagram</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="icon icon-tabler icons-tabler-filled icon-tabler-brand-instagram"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M16 3a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-8a5 5 0 0 1 -5 -5v-8a5 5 0 0 1 5 -5zm-4 5a4 4 0 0 0 -3.995 3.8l-.005 .2a4 4 0 1 0 4 -4m4.5 -1.5a1 1 0 0 0 -.993 .883l-.007 .127a1 1 0 0 0 1.993 .117l.007 -.127a1 1 0 0 0 -1 -1" />
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  rel="noreferrer"
                  target="_blank"
                  class="group flex size-14 p-1.5 items-center justify-center rounded-full border border-gray-500 text-white transition hover:opacity-75"
                >
                  <span class="sr-only">Facebook</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="icon icon-tabler icons-tabler-filled icon-tabler-brand-facebook"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 2a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -.883 .993l-.117 .007h-3v1h3a1 1 0 0 1 .991 1.131l-.02 .112l-1 4a1 1 0 0 1 -.858 .75l-.113 .007h-2v6a1 1 0 0 1 -.883 .993l-.117 .007h-4a1 1 0 0 1 -.993 -.883l-.007 -.117v-6h-2a1 1 0 0 1 -.993 -.883l-.007 -.117v-4a1 1 0 0 1 .883 -.993l.117 -.007h2v-1a6 6 0 0 1 5.775 -5.996l.225 -.004h3z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      ;
    </>
  );
};

export default Footer_new;
