import React, { useState } from 'react'
import Modal from "../components/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import Footer_new from '../components/Footer_new';

const Wallet_digital = () => {

    const [isOpen, setIsOpen] = useState(false);

    var settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 20000,
      slidesToShow: 6,
      slidesToScroll: 4,
      initialSlide: 0,
      cssEase: "linear",
      className: "wrapper",
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
  
            // dots: true,
          },
        },
        {
          breakpoint: 812,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
    <>
    <section>
      <div className="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:gap-32 lg:grid-cols-2">
        <div className="relative overflow-hidden lg:order-last order-last flex flex-col items-center">
            <img
              alt="Wallet Digital"
              src="https://img.invupos.com/clauweb/Wallet%20Digital/Hero/Wallet-digital-hero.webp"
              className="object-cover w-full h-full sm:h-full"
            />
            <div className="flex gap-8 pt-4">
              <a
                href="https://apps.apple.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.invupos.com/clauweb/Wallet%20Digital/Hero/Store%20Button%20Apple.svg"
                  alt="Download on the App Store"
                  className="h-18 w-auto"
                />
              </a>
              <a
                href="https://play.google.com/store"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://img.invupos.com/clauweb/Wallet%20Digital/Hero/Store%20Button%20Google%20Play.svg"
                  alt="Get it on Google Play"
                  className="h-18 w-auto"
                />
              </a>
            </div>
          </div>
          <div className="lg:py-28 text-left">
            <h1 className="text-7xl font-extrabold sm:text-9xl text-black">
              <span className="block">Wallet</span>
              <span
                className="mt-4 w-full ml-auto px-12 py-4 rounded-r-full text-white text-7xl font-bold flex items-center justify-end"
                style={{ background: "linear-gradient(to right, white, #f8857c, #fa5d5b)" }}
              >
                Digital
              </span>
            </h1>
            <p className="mt-8 text-gray-500 text-2xl w-full tracking-normal leading-relaxed">
              Simplifica tu vida, mejora la experiencia de tus clientes y potencia tu negocio con los beneficios de esta herramienta moderna y eficiente.
            </p>
            <div className="mt-8">
            <a
            class="inline-block rounded-full border border-[#FE7066] bg-[#FE7066] px-16 py-4 text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
            href="#"
            >
                Comienza Hoy
            </a>
            </div>
          </div>
        </div>
      </div>
    </section>


      {/* Nueva Sección */}


    <div className="px-4 py-16 mx-auto  md:max-w-full lg:max-w-screen-xl lg:px-8 lg:py-20">
    <div className="p-8 rounded-3xl sm:p-12 bg-[#F1F1F1]">
        <h2 className="text-4xl sm:text-5xl font-extrabold text-[#1d1d1f]">
        ¿Qué es la Clau card de CLAU?
        </h2>
        <div className="flex flex-col lg:flex-row lg:mt-12 mt-6">
        <div className="lg:w-3/5 content-center">
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-3 lg:gap-5 md:gap-5">
            {/* Card 1 */}
            <div className="rounded-2xl">
                <div className='relative'>
                    <img
                        className="rounded-2xl object-cover lg:h-44 h-28 h-28 h-44"
                        src="https://img.invupos.com/clauweb/Wallet%20Digital/Clau-card1.png"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black/80 to-transparent rounded-b-2xl">
                        <p className="hidden lg:block text-white text-center font-medium text-base mt-5">
                        Promociones únicas
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 2 */}
            <div className="rounded-2xl">
                <div className='relative'>
                    <img
                        className="rounded-2xl object-cover lg:h-44 h-28 h-28 h-44"
                        src="https://img.invupos.com/clauweb/Wallet%20Digital/Clau-card2.png"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black/80 to-transparent rounded-b-2xl">
                        <p className="hidden lg:block text-white text-center font-medium text-base mt-5">
                        QR al instante
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 3 */}
            <div className="rounded-2xl">
                <div className='relative'>
                    <img
                        className="rounded-2xl object-cover lg:h-44 h-28 h-28 h-44"
                        src="https://img.invupos.com/clauweb/Wallet%20Digital/Clau-card3.png"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black/80 to-transparent rounded-b-2xl">
                        <p className="hidden lg:block text-white text-center font-medium text-base mt-5">
                        Análisis en tiempo real
                        </p>
                    </div>
                </div>
            </div>
            </div>
            <div className="mt-8">
            <a
            class="inline-block rounded-full border border-[#fff] bg-[#fff] px-12 py-4 text-lg font-medium text-[#FF0000] focus:ring-3 focus:outline-hidden"
            href="#"
            >
                Crea tu CLAU CARD hoy
            </a>
            </div>
        </div>
        <div className="mb-6 lg:mb-0 lg:w-2/5 lg:pr-10 content-start text-justify lg:px-12 lg:mt-0 mt-6">
            <div className="text-gray-500 text-xl space-y-3 md:col-span-1 tracking-normal leading-relaxed">
                <p>
                Es más que una herramienta de pago; es la forma perfecta de premiar a tus clientes frecuentes y fidelizarlos.
                </p>
                <p>
                Cada transacción suma puntos que pueden canjear por descuentos, promociones exclusivas o beneficios únicos en tu negocio.
                </p>
            </div>
        </div>
        </div>
    </div>
    </div>

    <div className="px-4 py-16 mx-auto sm:max-w-7xl md:max-w-full lg:max-w-screen-xl lg:px-8 lg:py-20">
    <div class="w-full text-center">
      <h2 className="mb-6 font-sans font-bold leading-none md:mx-auto text-4xl sm:text-5xl font-extrabold text-[#1d1d1f]">
      Tu Giftcard a la mano en todo 
      momento
      </h2>
    </div>
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

        
    
        <div className="mt-12 flex justify-center">
          <div className="relative">
            {/* Imagen */}
            <img
              src="https://img.invupos.com/clauweb/Wallet%20Digital/QR%20Clau%20v1.png"
              className=""
              alt="QR Clau"
            />

            {/* Botón encima en la esquina inferior derecha */}
            <a
              href="#"
              className="absolute lg:bottom-8 lg:right-6 bottom-4 right-4  inline-block rounded-full border border-[#FE7066] bg-[#FE7066] lg:px-12 px-8 py-3 text-sm md:text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
            >
              Comienza Ahora
            </a>
          </div>
        </div>
      </div>
      <div className="grid gap-6 row-gap-5 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3">
        <div className="flex flex-col justify-between p-6 rounded-2xl shadow-sm bg-[#F8F8F8]">
          <div>
            <h2 className="text-[#FE7066] font-bold text-2xl mb-4 mt-2 text-center">Loyalty Card</h2>
            <ul className="list-disc list-inside font-light text-lg mt-3 tracking-normal leading-relaxed text-gray-700">
              <li>Tarjeta de fidelidad digital que se guarda en Apple Wallet.</li>
              <li>Muestra los puntos acumulados por el cliente.</li>
              <li>Se utiliza para canjear recompensas y beneficios dentro del programa de lealtad del comercio.</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-between p-6 rounded-2xl shadow-sm bg-[#F8F8F8]">
          <div>
            <h2 className="text-[#FE7066] font-bold text-2xl mb-4 mt-2 text-center">Gift Card</h2>
            <ul className="list-disc list-inside font-light text-lg mt-3 tracking-normal leading-relaxed text-gray-700">
              <li>Tarjeta de regalo digital adquirida en el comercio.</li>
              <li>En lugar de puntos, muestra el saldo disponible para compras.</li>
              <li>Permite a los clientes pagar con el saldo cargado en la tarjeta.</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-between p-6 rounded-2xl shadow-sm bg-[#F8F8F8]">
          <div>
            <h2 className="text-[#FE7066] font-bold text-2xl mb-4 mt-2 text-center">Punch Card</h2>
            <ul className="list-disc list-inside font-light text-lg mt-3 tracking-normal leading-relaxed text-gray-700">
              <li>Tarjeta digital afiliada al Wallet, similar a una cartilla de sellos física.</li>
              <li>Muestra la cantidad de sellos o punches acumulados según las compras realizadas.</li>
              <li>Al completar los sellos, el cliente obtiene recompensas o productos gratis según la promoción del comercio.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>


    <section class="bg-white">
      <div class="max-w-7xl px-6 py-6 mx-auto">
        <main class="relative z-20 w-full mt-8 md:flex md:items-center xl:mt-12">
          <div class="absolute w-full bg-[#F1F1F1] -z-10 md:h-[450px] rounded-3xl"></div>
            <div class="w-full p-6 bg-[#F1F1F1] md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
              <div class="mt-2 md:mx-6">
                <p class="text-4xl font-semibold  text-[#1d1d1f] md:text-5xl">Fideliza a tus clientes y recompénsalos en cada compra</p>
                <div className="mt-6">
                  <p className="font-light text-xl mt-3 tracking-normal leading-relaxed text-gray-700 max-w-2xl">
                  Con nuestro programa de fidelización, tus clientes acumulan puntos con cada compra, los cuales pueden canjear por descuentos, productos exclusivos y beneficios especiales.
                  </p>
                  <a
                    className="mt-6 inline-block rounded-full border border-[#FE7066] bg-[#FE7066] lg:px-12 px-8 py-3 text-sm md:text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden"
                  >
                    Empieza Ahora
                  </a>
                </div>
              </div>
              <img class="md:mx-6 lg:mt-0 mt-12 object-cover md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl" src="https://img.invupos.com/clauweb/Wallet%20Digital/Naked%20Lukas%281%29.png" alt="client photo" />
          </div>
        </main>
      </div>
    </section>
    
    <section>
  <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8 mt-10 mb-10">
    <div class="space-y-4 md:space-y-8">
    <div class="w-full text-center">
      <h2 className="mb-6 font-sans font-bold leading-none md:mx-auto text-3xl sm:text-4xl font-extrabold text-[#1d1d1f]">
      Los clientes que usan nuestra wallet digital regresan por más. 
      ¡Transforma compras en lealtad hoy mismo!
      </h2>
    </div>

      <div class="relative w-fit mx-auto">

      <div class="absolute inset-0 flex items-center justify-center z-0">
        <svg class="w-[200px] sm:w-[260px] md:w-[320px] lg:w-[400px] xl:w-[500px] aspect-square animate-spin-clockwise" viewBox="0 0 200 200">
          <circle
            cx="100"
            cy="100"
            r="90"
            fill="none"
            stroke="#fb7185"
            stroke-width="1"
            stroke-linecap="round"
            stroke-dasharray="300 120 80 15"
            stroke-dashoffset="25"
          />
        </svg>
      </div>

      <div class="absolute inset-0 flex items-center justify-center z-0">
        <svg class="w-[140px] sm:w-[120px] md:w-[240px] lg:w-[280px] xl:w-[380px] aspect-square rounded-full border-2 border-red-500 border-b-transparent animate-spin-counter" viewBox="0 0 200 200">
          <circle
            cx="100"
            cy="100"
            r="90"
            fill="none"
            stroke="#fb7185"
            stroke-width="1"
            stroke-linecap="round"
            stroke-dasharray="300 120 80 15"
            stroke-dashoffset="25"
          />
        </svg>
      </div>
  
      <Carousel
        className="z-10 relative ss"
        autoPlay={true}
        showThumbs={false}
        showArrows={false}
        infiniteLoop={true}
        interval={6000}
        swipeable={false}
        showStatus={false}
        showIndicators={false}
        swipeAnimationHandler={false}
        animationHandler="fade"
        handleTransition="fade"
      >
        <div>
          <img
            src="https://img.invupos.com/clauweb/Wallet%20Digital/Cover-card-1.webp"
            class="relative z-10 rounded-xl"
            alt="Imagen principal 1"
          />
        </div>
        <div>
          <img
            src="https://img.invupos.com/clauweb/Wallet%20Digital/Cover-card-2.webp"
            class="relative z-10 rounded-xl"
            alt="Imagen principal 2"
          />
        </div>
      </Carousel>
      
      </div>
      <div class="w-full text-center">
      <h2 className="mb-6 max-w-2xl font-sans font-semibold leading-none md:mx-auto text-xl sm:text-2xl font-extrabold text-[#1d1d1f]">
      Acumulación de puntos hasta pagos rápidos y seguros, lleva la experiencia de tus clientes al siguiente nivel
      </h2>
      <div className="mt-3">
          <a
            className="cursor-pointer inline-block rounded-full border border-[#FE7066] bg-[#FE7066] lg:px-12 px-8 py-3 text-sm md:text-lg font-medium text-white hover:bg-transparent hover:text-[#FE7066] focus:ring-3 focus:outline-hidden">
              Revoluciona Tu Negocio 
          </a>
      </div>
    </div>
    </div>
  </div>
</section>

  <Footer_new/>

    {isOpen && <Modal setIsOpen={setIsOpen} />}
    </>
  )
}

export default Wallet_digital